<template>
  <v-row
    v-bind="{
      justify: 'center',
    }"
    class="flex-wrap flex-row"
  >
    <template v-if="!uuid">
      <v-col cols="12">
        <label class="mb-18">
          ตรวจสอบคำสั่งซื้อด้วยระบบ OTP จากหมายเลขโทรศัพท์
        </label>

        <v-text-field
          v-model="phone"
          :error="$v.phone.$error"
          :error-messages="phoneErrorMessage"
          :color="colorBnn['color-bnn']"
          background-color="white"
          class="input-customer text-sm"
          type="text"
          maxlength="10"
          minlength="10"
          oninput="this.value=this.value.slice(0,this.maxLength).replace(/[^\d]+/g, '')"
          inputmode="decimal"
          placeholder="กรุณาระบุเบอร์โทรศัพท์"
          @keypress.enter="handleOtpAuthRequest"
        />
      </v-col>

      <v-col cols="12">
        <v-btn
          @click="handleOtpAuthRequest"
          :color="colorBnn['color-bnn']"
          :dark="!isBnn"
          block
          depressed
        >
          รับ OTP
        </v-btn>
      </v-col>
    </template>

    <template v-else>
      <v-col cols="12">
        <label class="mb-18">
          ระบบได้ทำการส่ง OTP ไปยังเบอร์โทรศัพท์ {{ phoneMarkdown }}
        </label>
        <v-text-field
          v-model="password"
          :error="$v.password.$error || isError"
          :error-messages="passwordErrorMessage"
          :color="colorBnn['color-bnn']"
          background-color="white"
          class="input-customer text-sm"
          type="text"
          maxlength="4"
          minlength="4"
          oninput="this.value=this.value.slice(0,this.maxLength).replace(/[^\d]+/g, '')"
          inputmode="decimal"
          placeholder="กรุณาระบุรหัส OTP"
          @keypress.enter="handleVerifyOtpAuth"
        />
      </v-col>

      <v-col cols="12" class="text-sm">
        หากท่านไม่ได้รับ SMS
        <span v-if="countDown"> ภายใน {{ countDown }} วินาที </span>
        <div
          class="link"
          :class="{ disabled: countDown > 0 }"
          @click="!countDown ? handleOtpAuthRequest(true) : null"
        >
          คลิกที่นี่เพื่อส่งอีกครั้ง
        </div>
      </v-col>

      <v-col cols="12">
        <v-btn
          @click="handleVerifyOtpAuth"
          :color="colorBnn['color-bnn']"
          :dark="!isBnn"
          block
          depressed
        >
          ยืนยัน OTP
        </v-btn>
      </v-col>
    </template>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import { validationMixin } from 'vuelidate'
import {
  required,
  minLength,
  maxLength,
} from 'vuelidate/lib/validators'

// components
import colorBnn from '@/config/color'
import isBnn from '@/config/isBnn'

export default {
  name: 'OtpAuth',

  mixins: [validationMixin],

  components: {},

  data() {
    return {
      colorBnn,
      isBnn,
      countDown: 60,
      verify: false,
      phone: null,
      password: null,
    }
  },

  validations: {
    phone: {
      required,
      maxLength: maxLength(10),
      minLength: minLength(10),
    },
    password: {
      required,
      maxLength: maxLength(4),
      minLength: minLength(4),
    },
  },

  computed: {
    campaignType() {
      return process.env.VUE_APP_CAMPAIGN_TYPE ?? 'BNN'
    },

    ...mapState({
      uuid: state => state.auth.uuid,
      data: state => state.auth.data,
      isSuccess: state => state.auth.isSuccess,
      isError: state => state.auth.isError,
    }),

    phoneErrorMessage() {
      if (this.$v.phone.$error) {
        if (!this.$v.phone.required) {
          return 'กรุณาระบุหมายเลขโทรศัพท์'
        }
        if (!this.$v.phone.minLength || !this.$v.phone.minLength) {
          return 'กรุณาตรวจสอบว่าระบุหมายเลขโทรศัพท์ถูกต้อง'
        }
      }
      return null
    },

    passwordErrorMessage() {
      if (this.$v.password.$error) {
        if (!this.$v.password.required) {
          return 'กรุณาระบุรหัส OTP'
        }
        if (
          !this.$v.password.minLength ||
          !this.$v.password.minLength
        ) {
          return 'กรุณาตรวจสอบว่าระบุรหัส OTP ถูกต้องหรือไม่'
        }
      }
      return this.isError
        ? 'กรุณาตรวจสอบว่าระบุรหัส OTP ถูกต้องหรือไม่'
        : null
    },

    phoneMarkdown() {
      return this.phone
        ? this.phone.replace(
            /(\d{3})(\d{3})(\d{2})(\d{2})/,
            '$1-xxx-xx$4',
          )
        : ''
    },
  },

  methods: {
    async handleOtpAuthRequest(resent = false) {
      this.$v.$touch()

      if (this.$v.phone.$invalid) {
        return
      }

      if (resent) {
        this.countDown = 60
      }

      const payload = {
        phone: this.phone,
        bu: this.campaignType,
      }
      await this.$store.dispatch('auth/otpRequest', payload)

      if (this.isSuccess) {
        this.$v.$reset()
        this.countDownTimer()
      }
    },

    async handleVerifyOtpAuth() {
      this.$v.$touch()

      if (this.$v.password.$invalid) {
        return
      }

      const payload = {
        uuid: this.uuid,
        password: this.password,
      }

      await this.$store.dispatch('auth/verifyOtp', payload)

      if (this.isSuccess) {
        this.$emit('success')
      }
    },

    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1
          this.countDownTimer()
        }, 1000)
      } else {
        return
      }
    },
  },
}
</script>

<style lang="stylus" scoped>
.link
  width: fit-content
  display: inline
  color: $color-bnn
  text-decoration: underline
  &.disabled
    color: $color-bnn-gray-light
    text-decoration: none
</style>
