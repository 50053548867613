<template>
  <div v-if="isLoading">
    <Load />
    <div class="order-lists-page"></div>
  </div>

  <v-container v-else class="order-lists-page">
    <v-row v-bind="{ justify: 'center' }" class="flex-wrap flex-row">
      <v-col cols="12" md="8" class="text-center">
        <h1>ตรวจสอบสถานะการสั่งซื้อ</h1>
      </v-col>
      <template v-if="isError || isAuthError">
        <v-col cols="12" md="6">
          <OtpAuth @success="handleGetOrderLists" />
        </v-col>
      </template>

      <template v-else>
        <v-col cols="12" md="8" v-if="!orders.length">
          <div class="order-empty">
            <div class="text-color-gray-light text-center">
              <font-awesome-icon
                :icon="{
                  prefix: 'fa',
                  iconName: 'file',
                }"
                size="lg"
              />
              <div>
                ท่านไม่มีรายการสั่งซื้อ
              </div>
            </div>
          </div>
        </v-col>

        <v-col cols="12" md="8" v-else>
          <div class="mb-3">
            รายการสั่งซื้อทั้งหมด
            {{ orders.length | currency }} รายการ
          </div>
          <orderCard
            v-for="(order, index) in orders"
            :key="index"
            :order="order"
          />
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import OrderCard from '@/components/bnn/OrderCard'
import OtpAuth from '@/components/bnn/OtpAuth'
import Load from './Load'

export default {
  name: 'OrderListsPage',

  components: {
    OrderCard,
    OtpAuth,
    Load,
  },

  computed: {
    campaignType() {
      return process.env.VUE_APP_CAMPAIGN_TYPE ?? 'BNN'
    },

    ...mapState({
      orders: state => state.order.data,
      isError: state => state.order.isError,
      isLoading: state => state.order.isLoading,
      isAuthError: state => state.auth.isError,
    }),
  },

  mounted() {
    this.handleGetOrderLists()
  },

  watch: {
    isAuthError() {
      if (!this.isAuthError) {
        this.handleGetOrderLists()
      }
    },
  },

  methods: {
    async handleGetOrderLists() {
      await this.$store.dispatch('order/getOrderLists')
    },
  },
}
</script>

<style lang="stylus" scoped>
.order-lists-page
  width: 100%
  min-height: calc(100dvh - (62px + 50px));
  margin-top: $header-height-mobile
  @media screen and (min-width: 1024px){
    margin-top: $header-height
  }

.order-empty
    min-height: calc(100dvh - 260px)
    display: flex;
    align-items: center
    justify-content: center

.order-list-item
    border: 1px solid $color-bnn-gray-light
    border-radius: 8px

.order-list-item-header
    padding: 12px
    border-bottom: 1px solid $color-bnn-gray-light

.order-list-item-body,
.order-list-item-footer
    padding: 0px 12px

.text-gray-medium-2
    color: $color-bnn-gray-medium-2

.product-item
    display: flex
    gap: 12px

.order-number
    font-weight: bold
    text-transform: uppercase

.order-status
    padding: 2px 4px
    border-radius: 4px
    font-size: 12px
    margin-left: 4px
    background: $color-bnn-gray-medium-2
    color: white

    &.complete
        background: green

    &.process
        background: $color-bnn

    &.create
        background: $color-bnn-gray-light-2
        color: $color-bnn-gray-medium

    &.cancel
        background: $color-bnn-red

.text-color-gray-light
  color: $color-bnn-gray-light
</style>
